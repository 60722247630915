import React from "react"

// Libraries
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "layouts/layout-primary"
import Container from "components/container"

// Components
import Posts from "components/posts"
import SEO from "components/seo/"

// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables"

const StyledBlogCategory = styled.div`
  padding-top: 104px;
  background-color: ${colors.silver__pale};
  .category {
    color: ${colors.turquoise__primary};
  }
  h1 {
    margin-bottom: 32px;
  }
`

const BlogCategory = (props) => {
  const {
    data: { contentfulBlogCategory: data },
  } = props

  const { title, blog_posts: blogPost } = data
  return (
    <Layout>
      <SEO title={`${title} | Blog | Arena Analytics`} />
      <StyledBlogCategory>
        <Container>
          <h1 className="h1">
            {blogPost && blogPost.length} Articles in{" "}
            <span className="category">{title}</span>
          </h1>
          {blogPost && <Posts data={blogPost} />}
        </Container>
      </StyledBlogCategory>
    </Layout>
  )
}

BlogCategory.propTypes = {
  data: PropTypes.shape({
    contentfulBlogCategory: PropTypes.shape({
      title: PropTypes.string.isRequired,
      blog_posts: PropTypes.shape({
        blogPost: PropTypes.shape({
          length: PropTypes.func.isRequired,
        }).isRequired,
        length: PropTypes.func.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}

export default BlogCategory

export const query = graphql`
  query ($id: String!) {
    contentfulBlogCategory(id: { eq: $id }) {
      slug
      title
      blog_posts {
        ...BlogCard
      }
    }
  }
`
